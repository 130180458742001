import React, { useState, useEffect } from 'react';
import RRU from 'react-redux-utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Classes, Dialog, Button, Intent } from '@blueprintjs/core';

import * as utils from 'app/utils';
import { dispatch } from 'app/state';
import * as selectors from 'app/state/reducers';
import Form from 'app/widgets/Form';
import {
  AgencySelect,
  ContragentSelect,
  TradepointSelect,
  BranchSelect,
  FixingIRTypesSelect,
  OverheadBackwallShowSelect,
  TradeNetworkSelect,
  TradepointProjectSelect,
} from 'app/widgets/ReferenceSelect';
import { LocalizedISODateInput } from 'app/widgets/LocalizedDateInput';
import { newFixation, setFixationField, saveFixation } from 'app/state/actionCreators/fixingIR';
import styles from './FixingIREditModal.module.css';

function onFieldChange(key, value) {
  dispatch(setFixationField(key, value));
}

const FixingIREditModal = React.memo(({ onClose }) => {
  const { t } = useTranslation();
  const showAgencyFilter = RRU.useSelector(selectors.canViewAgencies);
  const fixation = utils.useSelectorMemo((state) => state.fixingIR.fixation);
  const [valid, setValid] = useState(true);

  useEffect(() => {
    dispatch(newFixation());
  }, []);

  const onClickSave = async () => {
    try {
      await saveFixation();
      onClose(true);
    } catch {}
  };

  return (
    <Dialog
      icon="edit"
      title={t('create fixed ir')}
      isOpen
      canOutsideClickClose={false}
      onClose={() => onClose(false)}
    >
      <Form className={styles.form} value={fixation} onFieldChange={onFieldChange} onValidChange={setValid}>
        {showAgencyFilter && (
          <Form.Item
            label={t('agency')}
            acceptor={AgencySelect}
            name="agency_id__eq"
            transient
            multi={false}
            autoSelectFirst
          />
        )}
        <Form.Item
          label={t('contractor')}
          name="contragent_id__eq"
          dependsOn="agency_id__eq"
          required
          acceptor={ContragentSelect}
          multi={false}
          autoSelectFirst
        />
        <Form.Item
          name="network_id__in"
          label={t('network')}
          dependsOn="contragent_id__eq"
          acceptor={TradeNetworkSelect}
        />
        <Form.Item
          name="branch_id__in"
          dependsOn="contragent_id__eq"
          label={t('affiliate')}
          acceptor={BranchSelect}
        />
        <Form.Item
          name="project_id__in"
          label={t('project')}
          dependsOn="contragent_id__eq"
          acceptor={TradepointProjectSelect}
        />
        <Form.Item
          name="tradepoint_id"
          label={t('tradepoints')}
          required
          dependsOn="contragent_id__eq branch_id__in? network_id__in? project_id__in?"
          acceptor={TradepointSelect}
          filters={{
            has_ir_code__eq: true
          }}
          multi={false}
        />
        <Form.Item
          label={t('date from second')}
          required
          name="active_from_date"
          acceptor={LocalizedISODateInput}
        />
        <Form.Item
          label={t('date by')}
          required
          name="active_to_date"
          acceptor={LocalizedISODateInput}
        />
        <Form.Item
          label={t('questionnaire')}
          name="questionary_check"
          acceptor={FixingIRTypesSelect}
        />
        <Form.Item
          label={t('checkout')}
          name="checkout_check"
          acceptor={FixingIRTypesSelect}
        />
        <Form.Item
          label={t('backwall')}
          name="backwall_check"
          acceptor={FixingIRTypesSelect}
        />
        <Form.Item
          label={t('overhead')}
          name="overhead_check"
          acceptor={FixingIRTypesSelect}
        />
        <Form.Item
          label="Inspector Cloud"
          name="inspector_cloud"
          acceptor={FixingIRTypesSelect}
        />
        <Form.Item
          label={t('checkout after')}
          name="checkout_after_check"
          acceptor={FixingIRTypesSelect}
        />
        <Form.Item
          label={t('summary report')}
          name="summary_report"
          acceptor={FixingIRTypesSelect}
        />
        <Form.Item
          label={t('photo before')}
          name="photo_before"
          acceptor={FixingIRTypesSelect}
        />
        <Form.Item
          label={t('photo after')}
          name="photo_after"
          acceptor={FixingIRTypesSelect}
        />
        <Form.Item
          label={t('overhead backwall show as')}
          name="overhead_backwall_show_as"
          acceptor={OverheadBackwallShowSelect}
        />
      </Form>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={t('cancel')} onClick={() => onClose(false)} />
          <Button text={t('save')} disabled={!valid} intent={Intent.PRIMARY} onClick={onClickSave} />
        </div>
      </div>
    </Dialog>
  )
});

FixingIREditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FixingIREditModal;