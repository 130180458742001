import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { H2, H5, HTMLTable } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import * as utils from 'app/utils';
import * as selectors from 'app/state/reducers';
import Lightbox from 'app/widgets/Lightbox';
import ImageDescription from 'app/widgets/ImageDescription';
import NoCamera from 'app/assets/no-camera.svg';

import styles from '../TaskResult.module.css';

const renderImage = (idx, image, toggleLightbox) => (
  <input
    key={idx}
    type="image"
    className={styles.resultPhoto}
    src={image.src}
    alt={image.title}
    onClick={toggleLightbox.bind(null, idx)}
  />
);

const getIrUrl = (url) => {
  if (!url) {
    return url;
  }
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `https://hygiene.intrtl.com${url}`;
};

function makeLightboxImages({
  photos,
  title,
  description,
  hideCreatePhotoTime,
  taskStartUtcOffsetMinutes,
  taskGroupStartTimeUtc,
  startTime,
}) {
  const renderDateFunc = hideCreatePhotoTime ? utils.toLocaleDateString : utils.toLocaleString;
  return photos.map(p => ({
    src: getIrUrl(p.image_url) || NoCamera,
    title,
    description: {
      ...description,
      date: renderDateFunc(
        utils.parseIrPhotoDate(
          p.image_url,
          taskStartUtcOffsetMinutes,
          taskGroupStartTimeUtc,
          startTime,
        ) ?? description.date,
      ),
    },
  }))
}

function IrPhotoGroup({ title, photos, description }) {
  const hideCreatePhotoTime = RRU.useSelector(selectors.makeHasRight('webapp_contragent_hide_create_photo_time'));
  const taskStartUtcOffsetMinutes = RRU.useSelector('task.data.start_utc_offset_minutes');
  const startTime = RRU.useSelector('task.data.startDate');
  const taskGroupStartTimeUtc = RRU.useSelector('task.data.startUtcOffsetMinutes');
  const images = RRU.useMemoOptions(makeLightboxImages, {
    photos,
    title,
    description,
    hideCreatePhotoTime,
    taskStartUtcOffsetMinutes,
    taskGroupStartTimeUtc,
    startTime,
  });

  return (
    <tr>
      <td>
        <H5>{title}</H5>
        <div className={styles.resultPhotos}>
          <Lightbox
            thumbnailWidth="160px"
            thumbnailHeight="160px"
            images={images}
            renderImageFunc={renderImage}
            renderDescriptionFunc={ImageDescription}
          />
        </div>
      </td>
    </tr>
  );
}

IrPhotoGroup.propTypes = {
  title: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(PropTypes.shape({
    scene_type: PropTypes.string,
    image_url: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.object),
  })).isRequired,
  description: PropTypes.object.isRequired,
};

const IrPhotos = ({ photos, description }) => {
  const { t } = useTranslation();
  const groupedPhotos = _.groupBy(photos, 'scene_type');
  const groups = Object.keys(groupedPhotos);
  groups.sort();
  return (
    <>
      <H2>{t('photos')}</H2>
      <HTMLTable className={[styles.generalInfoTable, styles.irInfoTable]}>
        <tbody>
          {groups.map((g) => (
            <IrPhotoGroup title={g} key={g} photos={groupedPhotos[g]} description={description} />
          ))}
        </tbody>
      </HTMLTable>
    </>
  );
};

IrPhotos.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({
    image_url: PropTypes.string,
  })).isRequired,
  description: PropTypes.object.isRequired,
};

export default IrPhotos;
