import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { H2, HTMLTable, Icon } from '@blueprintjs/core';

import * as utils from 'app/utils';
import styles from '../TaskResult.module.css';

const getAssortmentCount = (irResult) => (
  _.filter(irResult?.report.assortment_achievement ?? [], (x) => x.facing_fact).length
);

const CONTRAGENT_ID2AILET_SUBDOMAIN = {
  89: 'asg',
  74: 'ehrmann',
  28: 'jti',
  130: 'cedo',
  136: 'tsp',
  84: 'hygiene',
};

function getAiletLink({ contragentId, ir_result }) {
  const subdomain = CONTRAGENT_ID2AILET_SUBDOMAIN[contragentId];
  return `https://${subdomain}.intrtl.com/visits/${ir_result.ir_visit_id}`;
}

function row(label, value) {
  return (
    <tr>
      <td>{label}:</td>
      <td className={styles.value}>{value || '—'}</td>
    </tr>
  );
}

function IrGeneral({ task }) {
  const { t } = useTranslation();

  const REQUIRED_TYPES = {
    required: t('required'),
    notrequired: t('not required'),
  };

  const irResult = task.ir_result;
  const posPhotoCount = _.uniq(_.values(irResult.result.report.photos).map(p => p.scene_type)).length;
  const assortmentCount = getAssortmentCount(irResult.result);

  return (
    <>
      <div className={styles.irTabHeader}>
        <H2>{t('general info')}</H2>

        <a
          target="_blank"
          rel="noreferrer"
          href={getAiletLink(task)}
        >
          {t('ailetSiteReport')} <Icon icon="share" />
        </a>
      </div>

      <HTMLTable className={[styles.generalInfoTable, styles.irInfoTable]}>
        <tbody>
          {row(t('photo before'), REQUIRED_TYPES[task.ir_photo_before])}
          {row(t('photo after'), REQUIRED_TYPES[task.ir_photo_after])}
          {row(t('questionnaires'), REQUIRED_TYPES[task.ir_questionaries_check])}
          {row(t('checkout'), REQUIRED_TYPES[task.ir_checkout_check])}
          {row(t('overhead'), REQUIRED_TYPES[task.ir_overhead_check])}
          {row(t('backwall'), REQUIRED_TYPES[task.ir_backwall_check])}
          {row(t('checkoutAfter'), REQUIRED_TYPES[task.ir_checkout_after_check])}

          {row(t('ir active from'), utils.toLocaleDateString(task.ir_date_from))}
          {row(t('ir active to'), utils.toLocaleDateString(task.ir_date_to))}

          {row(t('number of photos taken'), irResult.result.photosCounter)}
          {row(t('number of pos photos taken'), posPhotoCount)}

          {row(t('number of recognized products'), assortmentCount)}
        </tbody>
      </HTMLTable>
    </>
  );
}

const IrRequiredType = PropTypes.oneOf([
  'required',
  'notrequired',
  'notneed',
]);

IrGeneral.propTypes = {
  task: PropTypes.shape({
    ir_photo_before: IrRequiredType.isRequired,
    ir_photo_after: IrRequiredType.isRequired,
    ir_questionaries_check: IrRequiredType.isRequired,
    ir_checkout_check: IrRequiredType.isRequired,
    ir_overhead_check: IrRequiredType.isRequired,
    ir_backwall_check: IrRequiredType.isRequired,
    ir_checkout_after_check: IrRequiredType.isRequired,
    ir_date_from: PropTypes.string,
    ir_date_to: PropTypes.string,
    ir_result: PropTypes.shape({
      result: PropTypes.shape({
        photosCounter: PropTypes.number,
        report: PropTypes.shape({
          photos: PropTypes.objectOf(PropTypes.object),
          assortment_achievement: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
    }),
  }).isRequired,
};

export default IrGeneral;
