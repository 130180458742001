import { Intent } from '@blueprintjs/core';

import i18n from 'i18n';
import { exportFixedIR } from 'app/api';
import * as ACTIONS from 'app/state/actions';
import store from 'app/state';
import * as api from 'app/api';
import * as grids from 'app/state/actionCreators/grids';
import { showError } from 'app/widgets/toaster';
import { ISODate } from 'app/utils/date';
import alerts from 'app/widgets/alerts';

import { $setIn } from './index';

const NEW_FIXING = {
  branch_id: null,
  tradepoint_id: null,
};

export const newFixation = () => $setIn('fixingIR', { fixation: NEW_FIXING, fetching: false });

export const setFixationField = (key, value) => $setIn('fixingIR.fixation', { [key]: value, modified: true });

export const saveFixation = async () => {
  const { fixation } = store.getState().fixingIR;
  store.setOne('fixingIR.fetching', true);
  const data = {
    tradepoint_id: fixation.tradepoint_id,
    active_from_date: ISODate(fixation.active_from_date),
    active_to_date: ISODate(fixation.active_to_date),
    photo_after: fixation.photo_after,
    photo_before: fixation.photo_before,
    questionary_check: fixation.questionary_check,
    checkout_check: fixation.checkout_check,
    backwall_check: fixation.backwall_check,
    overhead_check: fixation.overhead_check,
    inspector_cloud: fixation.inspector_cloud,
    checkout_after_check: fixation.checkout_after_check,
    summary_report: fixation.summary_report,
    overhead_backwall_show_as: fixation.overhead_backwall_show_as,
  };

  try {
    await api.saveFixation(data);

    return true;
  } catch (error) {
    showError(error.message || i18n.t('error'));
    return false;
  } finally {
    store.setOne('fixingIR.fetching', false);
  }
};

export const exportData = (filters) => async (dispatch) => {
  dispatch({ type: ACTIONS.FIXED_IR_EXPORT_START });
  try {
    await exportFixedIR(filters);
    dispatch({ type: ACTIONS.FIXED_IR_EXPORT_DONE });
  } catch (error) {
    dispatch({ type: ACTIONS.FIXED_IR_EXPORT_ERROR, error });
  }
};

export const moveToArchive = (ids) => async (dispatch, getState) => {
  const state = getState();
  const { filters } = state.grids[api.BINDING_IR];

  const confirmed = await alerts.show({
    confirmButtonText: i18n.t('yes'),
    cancelButtonText: i18n.t('no'),
    icon: 'trash',
    intent: Intent.DANGER,
    content: (
      <>
        <div>{i18n.t('sure to delete ir binding')}</div>
      </>
    ),
  });

  if (!confirmed) {
    return null;
  }

  try {
    await api.deactivateIRBindings({
      filter: {
        ...filters,
        id__in: ids,
      }
    });
  } catch (e) {
    showError(e.message);
  } finally {
    dispatch(grids.fetch(api.BINDING_IR));
  }
};
